import { useState, createContext, useEffect } from 'react';
import message from 'antd/es/message';
import Spin from 'antd/es/spin';
//import { Auth } from 'aws-amplify';
import LoadingOutlined from '@ant-design/icons/LoadingOutlined';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { randomBytes } from 'crypto-browserify';

import { SetFeaturesUrl } from '../../Core/store/app-feature';
import {
  HttpHelper,
  SessionTimeoutError,
  ServiceUnavailableError,
  AuthenticationError,
  UserLockedError,
} from './httpHelper';
import { CustomModal } from '../common/CustomModal';
import { Base64URLEncode } from '../common/helpers';
import moment from 'moment';

var screen;

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const IssuerTypes = {
    AWS: 'AWS',
    AZURE: 'AZURE',
    AUTH0: 'AUTH0',
  };

  const destinations = [
    { type: 1, name: 'E-mail' },
    { type: 2, name: 'SMS' },
    { type: 3, name: 'App-Notification' },
  ];

  let baseURL = process.env.REACT_APP_API_ENDPOINT;
  const appID = process.env.REACT_APP_ID;

  const [appVersion, setAppVersion] = useState(null);
  const [history, setHistory] = useState(null);
  const [isLogout, setIsLogout] = useState(false);
  const [isTimeout, setIsTimeout] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);

  const [authInfo, setAuthInfo] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  const [kpi, setKpi] = useState(null);
  const [userDetails, setUserDetails] = useState({})
  const [alerts, setAlerts] = useState(false);
  const [apiErr, setApiErr] = useState(null);
  const [pageInfo, setPageInfo] = useState(null);
  const [oAuthPopupInstance, setOAuthPopupInstance] = useState(null);
  const [oAuthCodeVerifier, setOAuthCodeVerifier] = useState(null);
  const [AuthToken, setAuthToken] = useState(null);

  const [showNotificationIntervalId, setShowNotificationIntervalId] =
    useState(null);

  const [alertModalButtonLoading, setAlertModalButtonLoading] = useState(false);
  const [modalData, setModalData] = useState({
    icon: '',
    showModal: false,
    titleText: '',
    messageText: '',
    showCancelButton: false,
    confirmButtonText: 'Okay',
    type: null,
  });

  const [moduleState, setModuleState] = useState({
    moduleName: '',
    searchValue: '',
    sortValue: '',
    orderByValue: '',
    offset: 1,
    pageSize: 10,
    filterData: {
      isFilter: false,
    },
  });

  //
  if (!window.hasOwnProperty('REACT_APP_VERSION') && !appVersion) {
    setAppVersion(process.env.REACT_APP_VERSION);
  } else if (!appVersion) {
    setAppVersion(window.REACT_APP_VERSION);
  }

  // to check oauth popup for code param
  useEffect(() => {
    if (!oAuthPopupInstance) return;
    const timer = setInterval(async () => {
      if (!oAuthPopupInstance) {
        timer && clearInterval(timer);
        return;
      }
      let currentUrl = oAuthPopupInstance.location.href;
      if (!currentUrl) {
        return;
      }
      // if origins are not same then ignore
      const auth = authInfo
        ? authInfo
        : JSON.parse(localStorage.getItem('authInfo'));
      if (new URL(currentUrl).origin !== new URL(auth.redirect_url).origin) {
        return;
      }

      const searchParams = new URL(currentUrl).searchParams;
      const code = searchParams.get('code');
      console.log('oAuthCodeVerifier ', oAuthCodeVerifier);
      if (code) {
        oAuthPopupInstance.close();
        const oData = {
          grant_type: auth.grant_type,
          scope: auth.scope,
          client_id: auth.client_id,
          redirect_uri: auth.redirect_url,
          code: code,
          code_verifier: oAuthCodeVerifier,
        };
        let accessToken = await HttpHelper.GetFullToken(
          `${auth.TokenUrl}/token`,
          oData
        );
        setAuthToken(accessToken);
        // clear timer at the end
        setOAuthPopupInstance(null);
        timer && clearInterval(timer);

        let result = await AppLogin(accessToken.access_token);
        if (result) {
          history?.replace('/cockpit');
        }
        localStorage.setItem('refresh', accessToken.refresh_token);
        localStorage.setItem('expiry', accessToken.expires_in);
        localStorage.setItem('authInfo', JSON.stringify(auth));
        // localStorage.setItem('at', JSON.stringify(accessToken));
        sessionStorage.setItem('at', JSON.stringify(accessToken));

        // setTimeout(() => {
        //   setModalData({
        //     icon: '',
        //     showModal: true,
        //     titleText: 'Session Expired',
        //     messageText:'The session is timed out',
        //     showCancelButton: false,
        //     confirmButtonText: 'Okay',
        //     type: 'SessionTimeout',
        //   });
        //   sessionStorage.removeItem('oContext');
        //   sessionStorage.removeItem('userInfo');
        //   console.log(accessToken.expires_in * 1000)
        // }, 10000);
      }
    }, 500);
  }, [oAuthPopupInstance]);

  const clearNotificationInterval = () => {
    if (showNotificationIntervalId) {
      clearInterval(showNotificationIntervalId);
      setShowNotificationIntervalId(null);
    }
  };

  const startNotificationInterval = () => {
    if (showNotificationIntervalId === null)
      setShowNotificationIntervalId(setInterval(FetchAlerts, 60000));
  };

  useEffect(() => {
    if (window.location.pathname !== '/') {
      startNotificationInterval();
    }

    return () => {
      clearNotificationInterval();
    };
  }, []);

  const GetPowerBIToken = async (bpiScope) => {
    let codeVerifier = Base64URLEncode(randomBytes(32));

    const auth = authInfo
    ? authInfo
    : JSON.parse(localStorage.getItem('authInfo'));


    const oData = {
      grant_type: `refresh_token`, //authInfo.grant_type,authInfo.grant_type,
      scope: bpiScope,
      client_id: JSON.parse(localStorage.getItem('appInfo'))
        ? JSON.parse(localStorage.getItem('appInfo'))['AuthParams'].client_id
        : auth.client_id,
      redirect_uri: JSON.parse(localStorage.getItem('appInfo'))
        ? JSON.parse(localStorage.getItem('appInfo'))['AuthParams'].redirect_url
        : auth.redirect_url, //authInfo.redirect_url,
      refresh_token: localStorage.getItem('refresh')
        ? localStorage.getItem('refresh')
        : AuthToken.refresh_token, //AuthCode,
      code_verifier: codeVerifier,
    };

    let accessToken = await HttpHelper.AuthForm(
      `${
        JSON.parse(localStorage.getItem('appInfo'))
          ? JSON.parse(localStorage.getItem('appInfo'))['AuthParams'].TokenUrl
          : auth.TokenUrl
      }/token`,
      oData,
      'access_token'
    );
    return accessToken;
  };
  const resetModuleState = () => {
    setModuleState({
      moduleName: '',
      searchValue: '',
      sortValue: '',
      orderByValue: '',
      offset: 1,
      pageSize: 10,
      filterData: {
        isFilter: false,
      },
    });
  };

  const SetHttpContext = async (aSessionID, aAccessToken) => {
    // To Do - can we find a better Device ID
    let hardwareID = localStorage.getItem('deviceID');
    if (!hardwareID) {
      // Get the visitor identifier when you need it.
      const fp = await FingerprintJS.load({ monitoring: false });
      const result = await fp.get();
      hardwareID = result.visitorId;

      localStorage.setItem('deviceID', hardwareID);
    }
    // USE global variable which can be changed during depoy rather than build. this is defined in env.js file inside public folder.
    if (window.hasOwnProperty('REACT_APP_API_ENDPOINT')) {
      baseURL = window.REACT_APP_API_ENDPOINT;
      if (baseURL.endsWith('/'))
        baseURL = baseURL.substring(0, baseURL.length - 1);
    }
    let oContext = {
      BaseURL: baseURL,
      AppID: appID,
      AppVersion: appVersion,
      DeviceID: hardwareID,
      SessionID: aSessionID,
      AccessToken: aAccessToken,
    };
    HttpHelper.SetContext(oContext);
    sessionStorage.setItem('oContext', JSON.stringify(oContext));
  };

  const apiErrorText = (apiRes) => {
    setApiErr(apiRes);
    setTimeout(() => setApiErr(''), 5000);
  };

  const checkRefreshToken = async () => {
    console.log('checkRefreshToken');
    // const access = JSON.parse(localStorage.getItem('at'));
    const access = JSON.parse(sessionStorage.getItem('at'));
    const auth = JSON.parse(localStorage.getItem('authInfo'));
    const user = JSON.parse(sessionStorage.getItem('userInfo'));
    try {
      if (access && Object.keys(access).length > 0) {
        let tokenExpiry = new Date(
          JSON.parse(window.atob(access.access_token.split('.')[1])).exp * 1000
        );
        console.log(moment(tokenExpiry).diff(moment(new Date()), 'minutes'));
        let expiryMinutesDiff = moment(tokenExpiry).diff(
          moment(new Date()),
          'minutes'
        );
        if (expiryMinutesDiff <= 2) {
          console.log('Checking for refresh token', {expiryMinutesDiff, tokenExpiry});
          const oData = {
            grant_type: 'refresh_token',
            scope: auth.scope,
            client_id: auth.client_id,
            redirect_uri: auth.redirect_url,
            refresh_token: access.refresh_token,
          };
          let accessToken = await HttpHelper.GetFullToken(
            `${auth.TokenUrl}/token`,
            oData
          );
          sessionStorage.setItem('at', JSON.stringify(accessToken));
          setAuthToken(accessToken);
          console.log(accessToken);
          await SetHttpContext(user?.sessionID, accessToken['access_token']);
          return true;
        } else {
          return true;
        }
      }
      return true;
    } catch (e) {
      // ErrorHandler(e, true);
      openAuthPopup();
    }
  };

  const HttpGet = async (
    aFunction,
    aParams,
    aShowToast,
    aShowLoader = true,
    aIsCritical = true
  ) => {
    try {
      if (aShowLoader) setShowSpinner(true);
      await checkRefreshToken();
      return await HttpHelper.HttpGet(aFunction, aParams);
    } catch (e) {
      ErrorHandler(e, aShowToast ? null : aIsCritical);
      apiErrorText(e);
    } finally {
      if (aShowLoader) setShowSpinner(false);
    }
    return false;
  };

  const HttpGetList = async (
    aFunction,
    aParams,
    aShowLoader = true,
    aBackgroundCall = false
  ) => {
    try {
      if (aShowLoader && aBackgroundCall !== true) setShowSpinner(true);
      await checkRefreshToken();
      return await HttpHelper.HttpGetList(aFunction, aParams);
    } catch (e) {
      ErrorHandler(e, false);
    } finally {
      if (aShowLoader) setShowSpinner(false);
    }
    return false;
  };

  const HttpPost = async (
    aFunction,
    aPayload,
    aShowLoader = true,
    aShowToast,
    aIsCritical = true
  ) => {
    try {
      if (aShowLoader) setShowSpinner(true);
      await checkRefreshToken();
      console.log('accessToken HttpPost', aPayload);
      return await HttpHelper.HttpPost(aFunction, aPayload);
    } catch (e) {
      ErrorHandler(e, aShowToast ? null : aIsCritical);
    } finally {
      if (aShowLoader) setShowSpinner(false);
    }
    return false;
  };

  const HttpFile = async (aFunction, aPayload, aShowLoader = true) => {
    try {
      if (aShowLoader) setShowSpinner(true);
      await checkRefreshToken();
      return await HttpHelper.HttpFile(aFunction, aPayload);
    } catch (e) {
      ErrorHandler(e, true);
    } finally {
      if (aShowLoader) setShowSpinner(false);
    }
    return false;
  };

  const HttpPut = async (
    aFunction,
    aPayload,
    aShowLoader = true,
    aShowToast,
    aIsCritical = true
  ) => {
    try {
      if (aShowLoader) setShowSpinner(true);
      await checkRefreshToken();
      return await HttpHelper.HttpPut(aFunction, aPayload);
    } catch (e) {
      ErrorHandler(e, aShowToast ? null : aIsCritical);
    } finally {
      if (aShowLoader) setShowSpinner(false);
    }
    return false;
  };

  const HttpDelete = async (aFunction, aParams, aShowLoader = true) => {
    try {
      if (aShowLoader) setShowSpinner(true);
      await checkRefreshToken();
      return await HttpHelper.HttpDelete(aFunction, aParams);
    } catch (e) {
      ErrorHandler(e, true);
    } finally {
      if (aShowLoader) setShowSpinner(false);
    }
    return false;
  };

  const ErrorHandler = (errorInfo, isCritical) => {
    // based on the type of error we will set the modal data and show the modal
    setShowSpinner(false);
    setIsTimeout(false);
    if (errorInfo instanceof ServiceUnavailableError) {
      setModalData({
        icon: '',
        showModal: true,
        titleText: 'Service Unavailable',
        messageText: errorInfo.message,
        showCancelButton: false,
        confirmButtonText: 'Okay',
        type: 'ServiceUnavailable',
      });
    } else if (errorInfo instanceof SessionTimeoutError) {
      setIsTimeout(true);
      setModalData({
        icon: '',
        showModal: true,
        titleText: 'Session Expired',
        messageText: errorInfo.message,
        showCancelButton: false,
        confirmButtonText: 'Okay',
        type: 'SessionTimeout',
      });
      // commented because it was causing issue on sessiontimeout flow
      // sessionStorage.removeItem('oContext');
      // sessionStorage.removeItem('userInfo');
    } else if (errorInfo instanceof AuthenticationError) {
      // setIsTimeout(true);
      setModalData({
        icon: '',
        showModal: true,
        titleText: 'Authentication Error',
        messageText: errorInfo.message,
        showCancelButton: false,
        confirmButtonText: 'Okay',
        type: 'Authentication',
      });
    } else if (errorInfo instanceof UserLockedError) {
      // setIsTimeout(true);
      setModalData({
        icon: '',
        showModal: true,
        titleText: 'User Locked',
        messageText: errorInfo.message,
        showCancelButton: false,
        confirmButtonText: 'Okay',
        type: 'UserLocked',
      });
    } else if (isCritical) {
      setModalData({
        icon: '',
        showModal: true,
        titleText: 'Error',
        messageText: errorInfo.message,
        showCancelButton: false,
        confirmButtonText: 'Okay',
        type: 'error',
      });
    } else {
      showToastAlert({ type: 'error', message: errorInfo.message });
    }
    clearNotificationInterval();
  };

  const onErrorModalButtonClick = async () => {
    if (isTimeout) {
      await AppLogout(true);
      history?.push('/');
    }
    setModalData({ ...modalData, showModal: false });
  };

  const AppInfo = async (aHistory) => {
    await SetHttpContext();

    setHistory(aHistory);

    let apiResponse = await HttpGet('/auth/AuthInfo');
    if (apiResponse) {
      // set tab name
      document.title = apiResponse.TenantName;

      if (
        apiResponse.AuthParams &&
        Object.keys(apiResponse.AuthParams).length &&
        !apiResponse.HasCertificate
      ) {
        setAuthInfo(apiResponse.AuthParams);
      }

      setPageInfo({
        TenantID: apiResponse.TenantId,
        LoginImage: apiResponse.LoginImage,
        HomeTitle: apiResponse.HomeTitle,
        WelcomeText: apiResponse.WelcomeText,
        SecurityTitle: apiResponse.SecurityTitle,
        SecurityText: apiResponse.SecurityText,
        TenantName: apiResponse.TenantName,
      });

      //set localstorage regardless, no need to check
      localStorage.setItem(
        'bckgrndImg',
        `data:image/png;base64,${apiResponse?.LoginImage}`
      );
      localStorage.setItem('pageTitle', `${apiResponse?.TenantName}`);

      localStorage.setItem(
        'client',
        JSON.stringify(apiResponse.AuthParams.client_id)
      );
    }
  };

  const FetchUserProfileInfo = async (userId) => {

    let id = userId
    if(!userId) {
      const userInfo = JSON.parse(sessionStorage.getItem('userInfo'));
      id = userInfo?.userID;
    }

    const response = await HttpGet(`/UserProfile/UserDetails`, { userId: id }, false, false ,false);
    if (response) {
      setUserDetails(response);
    }
  };

  const AppLogin = async (aAccessToken) => {
    console.log('accessToken 7', aAccessToken);
    await SetHttpContext(null, aAccessToken);

    let apiResponse = await HttpPost('/auth/login', null);
    if (apiResponse) {
      setUserInfo({
        UserID: apiResponse.userID,
        FullName: apiResponse.fullName,
        Image: apiResponse.image,
        LastLogin: apiResponse.lastLogin,
        SessionID: apiResponse.sessionID,
        UserFeatures: apiResponse.features,
        TimeoutMins: apiResponse.inactivityTimeout,
      });
      FetchUserProfileInfo(apiResponse.userID)

      await SetHttpContext(apiResponse.sessionID, aAccessToken);
      // let urls = await SetFeaturesUrl(apiResponse.features);
      // console.log(urls);
      sessionStorage.setItem('userInfo', JSON.stringify(apiResponse));
      SetFeaturesUrl(apiResponse.features);
      startNotificationInterval();
    }

    return apiResponse;
  };

  const FetchKPI = async (params) => {
    let response = await HttpGet(
      '/Cockpit/KPI/list',
      {
        FacilityId: params?.OrgId ? params.OrgId : null,
      },
      false,
      false,
      false
    );
    if (response) {
      setKpi(response);

      // The URLs for features in sidebar are not set in the localStorage.
      // This causes the sidebar links to redirect to 'undefined' (whenever we refresh the page).
      // below code sets URLs for every feature in features array
      // and sets them in the localStorage
      const localFeaturesArray = JSON.parse(sessionStorage.getItem('userInfo'))[
        'features'
      ];
      SetFeaturesUrl(localFeaturesArray).then((featuresWithUrl) => {
        const userInfo = JSON.stringify({
          ...JSON.parse(sessionStorage.getItem('userInfo')),
          features: featuresWithUrl,
        });
        sessionStorage.setItem('userInfo', userInfo);
      });
    }
  };



  const FetchAlerts = async (params) => {
    let response = await HttpGet(
      '/Alert/ShowNotification',
      {},
      false,
      false,
      false
    );
    if (response !== null && response !== undefined) {
      setAlerts(response);
    }
  };

  const AppLogout = async (callLogoutApi = true) => {
    try {
      if (callLogoutApi) {
        return await HttpPost('/auth/logout', null);
      }
    } catch (e) {
      showToastAlert({ type: 'error', message: e.message });
    } finally {
      if (callLogoutApi) {
        setIsLogout(true);
        sessionStorage.removeItem('oContext');
        sessionStorage.removeItem('userInfo');
        clearNotificationInterval();
      }
      setPageInfo(null);
      setAuthInfo(null);
      setUserInfo(null);
      HttpHelper.SetContext(null);
    }
  };

  const AuthLogin = async (username, password) => {
    let accessToken;
    console.log('accessToken', accessToken);

    try {
      setShowSpinner(true);
      console.log('accessToken 2', accessToken);
      let authType = authInfo?.OpenIdType?.toUpperCase();
      console.log('accessToken 3', authType);
      if (authType === IssuerTypes.AUTH0) {
        const oData = {
          grant_type: authInfo.grant_type, //'password',
          username: username,
          password: password,
          scope: authInfo.scope, //'openid offline_access',
          client_id: authInfo.client_id,
          audience: authInfo.audience,
        };
        console.log('accessToken 4', oData);
        // accessToken = await HttpHelper.AuthForm(authInfo.TokenUrl, oData);
        accessToken = await HttpHelper.AuthForm(authInfo.TokenUrl, oData);
        // console.log(authRes, 'accessToken Auth Res');
        // authRes = 'id_token';
        // accessToken = authRes['id_token'];
      } else if (authType === IssuerTypes.AZURE) {
        openAuthPopup();
      }
      /*else if (authType === IssuerTypes.AWS) {
                try {

                    Auth.configure({
                        authenticationFlowType: authInfo.grant_type, //'USER_SRP_AUTH',
                        userPoolId: authInfo.audience, //'us-east-1_LwLQqfext'
                        userPoolWebClientId: authInfo.client_id //'7ef9h1ahm2ra77sg4ocnj3jta9'
                    });

                    const res = await Auth.signIn(username, password);
                    accessToken = res?.signInUserSession?.idToken?.jwtToken || null;
                }
                catch (e) {
                    throw e;
                }
            }*/
    } catch (e) {
      ErrorHandler(e, false);
      accessToken = null;
    } finally {
      setShowSpinner(false);
    }
    if (!accessToken) return null;
    console.log('accessToken 6', accessToken.access_token);

    return await AppLogin(accessToken);
  };

  const openAuthPopup = () => {
    let codeVerifier = oAuthCodeVerifier;
    const auth = authInfo
      ? authInfo
      : JSON.parse(localStorage.getItem('authInfo'));
    console.log('check by SSJ for auth error');

    if (!codeVerifier) {
      codeVerifier = Base64URLEncode(randomBytes(32));
      setOAuthCodeVerifier(codeVerifier);
    }
    console.log('codeVerifier ', codeVerifier);

    var dualScreenLeft =
      window.screenLeft !== undefined ? window.screenLeft : screen.left;
    var dualScreenTop =
      window.screenTop !== undefined ? window.screenTop : screen.top;

    var screenWidth = window.innerWidth
      ? window.innerWidth
      : document.documentElement.clientWidth
      ? document.documentElement.clientWidth
      : screen.width;
    var screenHeight = window.innerHeight
      ? window.innerHeight
      : document.documentElement.clientHeight
      ? document.documentElement.clientHeight
      : screen.height;

    var popupLeft = screenWidth / 2 - 500 / 2 + dualScreenLeft;
    var popupTop = screenHeight / 2 - 500 / 2 + dualScreenTop;

    // By MD as discussed
    auth.redirect_url = window.location.origin;
    console.log(
      `${auth.TokenUrl}/authorize?client_id=${auth.client_id}&response_type=code&redirect_uri=${auth.redirect_url}&response_mode=query&scope=${auth.scope}&code_challenge=${codeVerifier}`
    );

    let _oAuthPopup = window.open(
      `${auth.TokenUrl}/authorize?client_id=${auth.client_id}&response_type=code&redirect_uri=${auth.redirect_url}&response_mode=query&scope=${auth.scope}&code_challenge=${codeVerifier}`,
      'OAuth Authorization',
      `width=500,height=500,left=${popupLeft},top=${popupTop}`
    );
    setOAuthPopupInstance(_oAuthPopup);
  };

  const showToastAlert = (messageInfo) => {
    if (messageInfo.type === 'error') {
      message.error(
        {
          content: messageInfo.message,
          className: 'error-toast-msg',
        },
        5
      );
    } else {
      message.success(
        {
          content: messageInfo.message,
          className: 'success-toast-msg',
        },
        5
      );
    }
  };

  const getTitle = (id, features) => {
    let result;

    features.forEach((item) => {
      if (item.id === id) {
        result = { title: item.name, subTitle: item.description };
      } else if (item.submenu?.length && !result) {
        const result1 = getTitle(id, item.submenu);
        if (result1) result = result1;
      }
    });
    return result;
  };

  const checkModulePermission = (moduleList, module, access) => {
    let hasAccess = false;
    moduleList.forEach((feature) => {
      if (
        feature.id === module &&
        feature.apis &&
        feature.apis.includes(access)
      ) {
        hasAccess = true;
      }
      if (!hasAccess && feature.submenu && feature.submenu.length) {
        hasAccess = checkModulePermission(feature.submenu, module, access);
      }
    });
    return hasAccess;
  };

  const acl = (module, access) => {
    let hasAccess = false;
    let UserFeatures = [];
    if (JSON.parse(sessionStorage.getItem('userInfo'))) {
      UserFeatures = JSON.parse(sessionStorage.getItem('userInfo'))['features'];
    } else {
      UserFeatures = userInfo.UserFeatures;
    }
    if (UserFeatures.length) {
      hasAccess = checkModulePermission(UserFeatures, module, access);
    }
    return hasAccess;
  };

  const contextValue = {
    IssuerTypes,
    moduleState,
    setModuleState,
    resetModuleState,
    acl,
    getTitle,
    appID,
    appVersion,
    authInfo,
    pageInfo,
    userInfo,
    isLogout,
    destinations,
    AppInfo,
    AppLogin,
    AppLogout,
    AuthLogin,
    showToastAlert,
    ErrorHandler,
    HttpDelete,
    HttpGet,
    HttpGetList,
    HttpPut,
    HttpPost,
    HttpFile,
    setAlertModalButtonLoading,
    apiErr,
    FetchKPI,
    kpi,
    alerts,
    FetchAlerts,
    GetPowerBIToken,
    userDetails,
    FetchUserProfileInfo
  };

  if (
    localStorage.getItem('pageTitle') &&
    localStorage.getItem('pageTitle').trim() !== ''
  ) {
    document.title = localStorage.getItem('pageTitle');
  }

  return (
    <AppContext.Provider value={contextValue}>
      <Spin
        wrapperClassName="h-full"
        spinning={showSpinner}
        indicator={
          <LoadingOutlined style={{ fontSize: 26, color: '#4E2C90' }} spin />
        }
      >
        {children}
      </Spin>
      <CustomModal
        icon={modalData.icon}
        showModal={modalData.showModal}
        isLoading={alertModalButtonLoading}
        titleText={modalData.titleText}
        messageText={modalData.messageText}
        showCancelButton={modalData.showCancelButton}
        handleCancel={() => setModalData({ ...modalData, showModal: false })}
        confirmButtonText={modalData.confirmButtonText}
        handleConfirm={() => onErrorModalButtonClick()}
        zIndex={1001}
      />
    </AppContext.Provider>
  );
};
